@import 'styles/_constants.scss';

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1005;

  .modal-overlay {
    background-color: rgba(22, 29, 33, .9);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1005;
  }

  .close {
    color: $color-white;
    text-transform: uppercase;
    position: absolute;
    right: calc(50% - 510px);
    top: 70px;
    cursor: pointer;
    z-index: 1015;

    &.narrow {
      right: calc(50% - 275px);
    }
  }

  .modal {
    background-color: $color-white;
    position: fixed;
    top: 100px;
    bottom: 100px;
    width: 1020px;
    left: calc(50% - 510px);
    z-index: 1015;

    &.narrow {
      width: 550px;
      left: calc(50% - 275px);
      height: 500px;
      bottom: unset;
    }

    .content-container {
      position: relative;
      height: 100%;
      overflow-y: auto;

      .content {
        position: absolute;
        top: 40px;
        bottom: 40px;
        left: 30px;
        right: 30px;
        overflow-y: auto;

        .modal-room {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: center;
          align-items: flex-start;

          .mobile-room-name {
            display: none;
          }

          .mobile-gallery {
            display: none;
          }

          .room-basics {
            width: 330px;
            display: inline-block;
            vertical-align: top;

            .room-name {
              font-weight: bold;
              font-size: $font-size-xl;
              padding: 20px;
              border-left: $color-grey-5 solid 10px;
            }

            .section-title {
              margin: 20px 0;
              padding-bottom: 5px;
              font-size: $font-size-l;
              font-weight: bold;
              border-bottom: 1px solid $color-grey-3;
            }

            .guests {
              font-size: $font-size-m;

              .guest-count, .beds {
                display: inline-block;
                width: 50%;
                vertical-align: top;
              }

              .bed-count {
                margin-bottom: 10px;
              }

              svg {
                height: 25px;
                width: 31px;

                &.guest-icon {
                  width: 24px;
                }

                &.sofa-bed-icon {
                  margin-top: -5px;
                }

                &.foldable-bed-icon {
                  margin-top: -8px;
                }
              }

              span {
                line-height: 25px;
                vertical-align: top;
                margin-left: 10px;
              }
            }
          }

          .desktop-gallery {
            width: 600px;
            height: 400px;
            display: inline-block;
            vertical-align: top;

            .gallery {
              img {
                width: 600px;
                height: 400px;
              }

              .thumbnails {
                width: 100%;

                img {
                  width: 115px;
                  height: 70px;
                  margin-right: 6px;
                  margin-top: 2px;
                  cursor: pointer;

                  &:nth-child(5n+0) {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          .amenities-title {
            font-size: $font-size-m;
            font-weight: bold;
            margin-top: 40px;
          }

          .amenities {
            font-size: $font-size-m;
            padding-inline-start: 15px;
            columns: 2;
            column-gap: 25px;

            li {
              margin-bottom: 5px;
            }
          }
        }

        .modal-property {

          .gallery {
            width: 960px;
            height: 640px;

            img {
              width: 960px;
              height: 520px;
            }

            .thumbnails {
              width: 100%;

              img {
                width: 115px;
                height: 70px;
                margin-right: 6px;
                margin-top: 2px;
                cursor: pointer;

                &:nth-child(8n+0) {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .gallery {

          .current {
            position: relative;
            max-height: 100%;
            overflow: hidden;

            .image {
              background-repeat: no-repeat;
              object-fit: cover;
            }

            .left {
              position: absolute;
              left: 10px;
              top: calc(50% - 25px);

              svg {
                width: 30px;
              }
            }

            .right {
              position: absolute;
              right: 10px;
              top: calc(50% - 25px);
              cursor: pointer;

              svg {
                width: 30px;
              }
            }
          }

          .thumbnails {
            width: 100%;
            margin-top: 3px;

            img {
              background-repeat: no-repeat;
              object-fit: cover;
            }
          }
        }

        .modal-reservation {
          .conflicts-container {
            margin-top: 30px;
            color: $color-red-3;

            .conflict-container {
              margin-bottom: 5px;
            }

            svg {
              width: 14px;
              height: 14px;
              margin-right: 5px;
            }
          }

          .selected-rooms {
            margin-top: 30px;
            margin-bottom: 10px;
          }

          table {
            text-align: left;
            border-spacing: 0;
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 20px;

            th {
              padding: 10px;
              background-color: $color-grey-1;
              border: 1px solid $color-grey-3;

              &:not(:first-child) {
                border-left: none;
                text-align: right;
              }

              &[colspan="1"] {
                border-right: none;
              }

              .unavailable {
                color: $color-red-3;
                text-transform: uppercase;
                font-size: 80%;
              }
            }

            td {
              padding: 10px;
              border: 1px solid $color-grey-3;

              &:first-child {
                border-right: 0;
              }

              &:nth-child(2) {
                border-left: 0;
                text-align: right;
              }

              .new-price {
                margin-right: 10px;
              }

              .requested-price {
                text-decoration: line-through;
              }

              .price-line {
                margin-bottom: 10px;
              }

              .unit-total-price {
                font-weight: 700;
              }

              .price-conflict {
                text-decoration: line-through;
                margin-right: 10px;
              }
            }
          }

          .total-amount {
            text-align: right;
          }

          .requested-total-amount {
            text-align: right;
            text-decoration: line-through;
            margin-bottom: 5px;
          }

          .controls {
            margin-top: 30px;

            .back-button {
              display: inline-block;
              width: auto;
              text-align: center;
              min-width: 120px;
              padding: 0 30px;
              height: 45px;
              font-size: $font-size-m;
              text-transform: uppercase;
              margin-right: 20px;
              background-color: $color-white;
              color: $color-grey-5;
              border: 2px solid $color-grey-5;
              box-sizing: border-box;

              &:hover {
                background-color: $color-grey-1;
              }
            }
          }
        }

        .booking-confirmation {
          text-align: center;

          .confirmation-title {
            color: $color-green-1;
            font-size: 30px;
            margin: 35px 0 40px;
          }

          .confirmation-text {
            margin-bottom: 15px;
          }

          .controls {
            margin-top: 80px;

            .back-button {
              display: inline-block;
              width: auto;
              text-align: center;
              min-width: 160px;
              padding: 0 30px;
              height: 45px;
              font-size: $font-size-m;
              text-transform: uppercase;
              margin-right: 20px;
              background-color: $color-white;
              color: $color-grey-5;
              border: 2px solid $color-grey-5;
              box-sizing: border-box;

              &:hover {
                background-color: $color-grey-1;
              }
            }
          }
        }

      }
    }
  }
}

@media (max-width: 1080px) {

  .modal-container {
    .close {
      right: calc(50% - 280px);
      top: 30px;
    }

    .modal {
      width: 560px;
      top: 60px;
      left: calc(50% - 280px);
      bottom: 80px;

      &.narrow {
        .content-container {
          .content {
            top: 40px;
            bottom: 40px;
            left: 30px;
            right: 30px;
          }
        }
      }

      .content-container {

        .content {
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;

          .modal-property {
            height: 100%;

            .gallery {
              width: 560px;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .modal-room {
            .mobile-room-name {
              display: block;
              font-size: $font-size-l;
              padding: 20px 25px;
              font-weight: bold;
            }

            .mobile-gallery {
              display: block;
              width: 560px;
              height: 240px;

              .gallery {
                height: 240px;

                .current {
                  .image {
                    width: 560px;
                    height: 240px;
                  }
                }
              }

              .thumbnails {
                display: none;

              }
            }

            .room-basics {
              display: block;
              width: auto;
              padding: 25px;

              .room-name {
                display: none;
              }
            }

            .desktop-gallery {
              display: none;
            }

          }

          .modal-reservation {
            padding: 30px 25px;
          }

        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-container {
    .modal-overlay {
      background-color: $color-grey-5;
    }

    .close {
      right: 20px;
      top: 20px;

      &.narrow {
        right: 20px;
      }
    }

    .modal {
      width: 100%;
      left: 0;
      bottom: 0;

      .content-container {
        .content {
          .modal-property {
            background-color: $color-grey-5;

            .gallery {
              width: 100%;

              .current {
                .image {
                  width: 100%;
                }
              }
            }
          }

          .modal-room {
            .room-basics {
              margin-right: 0;
            }

            .mobile-gallery {
              width: 100%;

              .gallery {
                .current {
                  .image {
                    width: 100%;
                  }
                }
              }
            }
          }

          .modal-reservation {
            .date-overview {
              margin-bottom: 25px;
              margin-right: 0;
              display: block;

              .date-value {
                width: 100%;
              }
            }

            .selected-rooms {
              margin-top: 10px;
            }
          }
        }
      }

      &.narrow {
        width: 100%;
        left: 0;
        bottom: 0;
        height: auto;
      }
    }
  }
}
