@import 'styles/_constants.scss';

.total-amount {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  font-weight: bold;

  span {
    color: $color-red-3;
  }

  .price-skeleton {
    width: 40px;
    height: 20px;
    background-color: $color-grey-skeleton;
  }
}

.date-overview {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;

  .date-value {
    width: 165px;
    height: 60px;
    background-color: $color-grey-1;
    border: 1px solid $color-grey-3;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;

    div:first-child {
      margin-bottom: 5px;
    }
  }
}

.booking-page-header-footer {
  background-color: $color-grey-5;
  color: $color-white;
  height: 90px;
  width: 100%;

  .content {
    width: 1010px;
    line-height: 90px;
    padding: 0 20px;
    margin: 0 auto;

    .select-language {
      .select-input {
        color: $color-white;
        border-color: $color-white;
      }

      path {
        fill: $color-white;
      }
    }
  }

  .property-name {
    font-size: 25px;
    font-weight: bold;
    margin-top: -25px;
  }
}

.booking-form {
  background-color: $color-white;
  padding: 20px;
  color: $color-grey-5;

  .back {
    font-size: $font-size-m;
    color: $color-blue-2;
    cursor: pointer;
    margin-bottom: 40px;

    &:hover {
      text-decoration: underline;
    }
  }

  .step-header {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    border-left: $color-grey-5 solid 10px;
    margin-bottom: 40px;
  }

  .overview-section {
    margin-bottom: 30px;

    .section-title {
      display: inline-block;
      width: 140px;
      padding: 0 20px 0 30px;
      vertical-align: top;
      height: 100%;
      box-sizing: border-box;
    }

    .section-content {
      display: inline-block;
      vertical-align: top;
      padding-left: 40px;
      border-left: $color-grey-3 solid 1px;
      box-sizing: border-box;
      width: 560px;

      .total-amount-conflict {
        text-decoration: line-through;
        text-align: right;
        margin-bottom: 5px;
      }

      .overview-controls {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: space-between;
        align-items: stretch;

        .show-details {
          font-size: $font-size-m;
          color: $color-blue-2;
          text-transform: uppercase;
          cursor: pointer;
          order: 0;
          flex: 0 1 auto;
          align-self: auto;

          &:hover {
            text-decoration: underline;
          }
        }

      }
    }

    .booking-rooms {
      margin-bottom: 25px;

      .booking-room {
        width: 480px;
        height: 40px;
        background-color: $color-grey-1;
        border: 1px solid $color-grey-3;
        padding: 10px;
        box-sizing: border-box;
        font-weight: bold;
      }
    }

  }

  form {
    margin-left: 140px;
    width: 560px;
    margin-bottom: 80px;

    label {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
    }

    .input, .textarea {
      margin-bottom: 25px;

      input, textarea, select {
        width: 100%;
        border: 1px solid $color-grey-5;
        box-sizing: border-box;
      }

      textarea {
        height: 140px;
        padding: 15px;
      }

      .select {
        select {
          padding-right: 30px;
        }

        svg {
          height: 20px;
          width: 20px;
          right: 8px;
          top: 13px;

        }
      }
    }

    .buttons {
      text-align: right;

      .button, .back-button {
        display: inline-block;
        width: auto;
        text-align: center;
        min-width: 120px;
        padding: 0 30px;
        height: 45px;
        font-size: $font-size-m;
        text-transform: uppercase;
      }

      .back-button {
        margin-right: 20px;
        background-color: $color-white;
        color: $color-grey-5;
        border: 2px solid $color-grey-5;
        box-sizing: border-box;

        &:hover {
          background-color: $color-grey-1;
        }
      }
    }

  }
}

@media (max-width: 1080px) {
  .booking-page-header-footer {
    height: 45px;

    .content {
      line-height: 45px;
      width: 540px;

      .property-name {
        font-size: $font-size-xl;
      }
    }
  }

  .booking-form {
    padding: 20px 100px 0 0;

    .back {
      margin-bottom: 15px;
      margin-left: 30px;
    }

    .overview-section {
      .section-title {
        display: none;
      }

      &:nth-child(4) {
        .section-title {
          display: block;
          margin-bottom: 10px;
          width: auto;
        }
      }

      .section-content {
        border: none;
        padding-left: 30px;
        width: 100%;
      }

      .booking-rooms {
        .booking-room {
          width: 100%;
        }
      }

    }

    .form {
      form {
        margin-left: 30px;
        width: 470px;
        margin-bottom: 0;

        .buttons {
          padding-bottom: 100px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .booking-page-header-footer {
    .content {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .booking-form {
    padding-right: 30px;
    .overview-section {
      margin-bottom: 0;

      .date-overview {
        margin-bottom: 15px;
        width: 100%;

        .date-value {
          width: 100%;
        }
      }

      .overview-controls {
        margin-bottom: 50px;
      }
    }

    .form {
      form {
        width: 100%;
        padding-right: 30px;
        box-sizing: border-box;

        .buttons {
          padding-bottom: 50px;

          .back-button, button {
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
