@import 'styles/_constants.scss';

.room {
  margin-top: 40px;
  height: 240px;
  padding: 20px;
  background-color: $color-white;
  box-shadow: $box-shadow;

  .main-image {
    display: inline-block;
    position: relative;
    height: 240px;
    width: 240px;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-right: 25px;
    cursor: pointer;

    .open-gallery {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: $font-size-xs;
      text-transform: uppercase;
      padding: 12px;
      background: rgba(255,255,255,0.8);
    }
  }

  .details {
    display: inline-block;
    vertical-align: top;
    width: 250px;
    height: 100%;
    margin-right: 25px;

    .container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      height: 100%;

      .title {
        font-size: $font-size-xl;
        line-height: 30px;
        margin-bottom: 10px;
        display: none;
      }

      .basic {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        width: 100%;

        .title {
          font-size: $font-size-xl;
          line-height: 30px;
          margin-bottom: 10px;
          display: block;
        }

        .guests-and-beds {
          margin-bottom: 20px;
          padding-right: 20px;

          .guest-bed-count {
            font-size: $font-size-s;
            color: $color-grey-4;
            margin-bottom: 5px;

            svg {
              height: 18px;
              width: 20px;

              &.guest-icon {
                width: 20px;
              }
            }

            span {
              line-height: 25px;
              vertical-align: top;
              margin-left: 10px;
            }
          }
        }
      }

      .show-amenities {
        font-size: $font-size-m;
        color: $color-blue-2;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .price-container {
      display: none;
    }
  }

  .calendar {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    margin-right: 25px;

    label {
      margin: 0;
    }

    .rc-calendar {
      font-family: 'Roboto', sans-serif;
      font-size: 11px;
      width: 240px;
      height: 240px;
      box-shadow: unset;

      &-picker {
        left: 0 !important;
      }

      &-header {
        background-color: $color-grey-1;
      }

      &-body {
        height: 205px;
        padding: 5px 10px;
      }

      &-date {
        background-color: $color-white;
        position: relative;
        width: 31px;
        cursor: default;

        &.available {
          color: $color-blue-3;
          font-weight: bold;
        }

        &.selected {
          color: $color-white;
          background-color: $color-blue-1;
        }

        &.check-in {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          z-index: 1;

          &::before {
            content: '';
            position: absolute;
            background-color: $color-blue-3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 15px;
            z-index: -1;
          }
        }

        &.check-out {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          z-index: 1;

          &::before {
            content: '';
            position: absolute;
            background-color: $color-blue-3;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 15px;
            z-index: -1;
          }
        }
      }
    }
  }

  .popular-amenities {
    display: inline-block;
    vertical-align: top;
    font-size: $font-size-s;
    color: $color-grey-4;
    width: 240px;
    margin-right: 25px;
    margin-top: 40px;
    margin-left: -40px;
    padding-left: 40px;
    border-left: 1px solid $color-grey-2;
    padding-bottom: 20px;
    max-height: calc(100% - 60px);
    overflow: auto;

    .amenities {
      padding-inline-start: 15px;
      margin-block-start: 0;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .controls {
    display: inline-block;
    vertical-align: top;
    text-align: right;
    font-size: $font-size-s;
    color: $color-grey-4;
    width: 200px;
    height: 100%;

    .controls-info {
      .sold-out {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-size: 18px;
        color: $color-red-3;
        font-weight: 700;
      }

      .change-dates {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 20px;
        font-size: $font-size-m;
        color: $color-blue-2;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .controls-price {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-end;
      height: 100%;

      .price-container {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;

        img {
          height: 25px;
        }

        svg {
          height: 25px;
          width: 48px;
        }

        .price {
          margin-bottom: 10px;
          font-size: 25px;
          color: $color-grey-5;
          font-weight: bold;
        }
      }

      .choose-rooms {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        width: 100%;

        .select {
          select {
            width: 160px;
            height: 45px;
            color: $color-blue-2;
            border: 2px solid $color-blue-2;
            padding: 0 25px 0 15px;
            background-color: transparent;

            &:hover, &:focus, &:active {
              color: $color-blue-3;
              border-color: $color-blue-3;
            }

            &.green {
              color: $color-white;
              background-color: $color-green-1;
              border-color: $color-green-1;

              &:hover, &:focus, &:active {
                color: $color-white;
                background-color: $color-green-2;
                border-color: $color-green-2;
              }
            }
          }

          svg {
            right: 12px;
            top: calc(50% - 4px);
          }
        }
      }
    }
  }
}

.unit {
  padding: 10px 20px;
  background-color: $color-white;
  border-top: 1px solid $color-grey-3;
  box-shadow: $box-shadow;
  font-size: $font-size-m;

  .name {
    width: 290px;
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
  }

  .controls {
    width: 415px;
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;

    .input {
      display: inline-block;

      &:first-child {
        margin-right: 45px;
      }

      .select {
        display: inline;

        select {
          width: 65px;
          padding: 0 20px 0 15px;
          border: 1px solid $color-grey-2;
          background-color: transparent;
          height: 40px;
        }

        svg {
          right: 8px;
          top: calc(50% - 3px);
        }
      }
    }
  }

  .delete {
    width: 250px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;

    button {
      display: none;
    }

      span {
      font-weight: bold;
      color: $color-grey-4;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1080px) {
  .room {
    padding: 10px 10px 15px;
    height: auto;

    .calendar {
      display: none;
    }

    .main-image {
      display: block;
      height: 135px;
      width: 100%;

      .open-gallery {
        top: 0;
        left: 0;
        bottom: unset;
        right: unset;
      }
    }

    .details {
      display: block;
      height: auto;
      width: 100%;

      .container {
        margin-top: 20px;

        .basic {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;

          .title {
            padding-right: 20px;
            display: block;
          }

          .guests-and-beds {
            margin-bottom: 0;
          }

          .price-container {
            display: block;
            text-align: right;

            .price {
              font-size: $font-size-xl;
              color: $color-grey-5;
            }
          }
        }

        .controls-info {
          .sold-out {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 18px;
            color: $color-red-3;
            font-weight: 700;
          }
        }

        .show-amenities {
          display: none;
        }
      }
    }

    .controls {
      display: block;
      height: auto;
      width: auto;

      .controls-info {
        display: none;
      }

      .controls-price {

        .price-container{
          display: none;
        }

        .choose-rooms {
          width: 100%;
          margin-top: 20px;

          .select {
            select {
              width: 100%;
            }
          }
        }
      }
    }

  }

  .unit {
    padding: 25px 10px 10px;

    .name {
      display: block;
      margin-bottom: 20px;
    }

    .controls {
      display: block;
      text-align: left;
    }

    .delete {
      width: 100%;
      margin-top: 20px;

      span {
        display: none;
      }

      button {
        display: block;
        width: 100%;
        height: 40px;
        background-color: $color-grey-1;
        border: 1px solid $color-grey-5;
        color: $color-grey-5;
        text-transform: uppercase;
        font-size: $font-size-s;
      }
    }
  }
}

@media (max-width: 768px) {
  .room {
    .details {
      .container {
        .title {
          display: block;
        }

        .basic {
          .title {
            display: none;
          }
        }
      }
    }
  }
}
