@import 'styles/_constants.scss';

.skeleton-app {
  .skeleton {
    background-color: $color-grey-skeleton !important;
  }

  .select-language {
    .input {
      width: 85px;
      height: 25px;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .property-name {
    border: none !important;
    margin-bottom: 35px;
    width: 55%;
    height: 10px;
  }

  .property-description {
    .location {
      height: 25px;
      width: 40%;
    }

    .description {
      .line {
        height: 25px;
        margin-bottom: 15px;
      }
    }
  }

  .stay {
    padding-top: 25px !important;
    height: 95px !important;

    .input {
      height: 40px;
      width: 230px;

      &.text-input {
        width: 120px;
      }
    }

    .button {
      height: 70px;
      width: 290px;
    }
  }

  .rooms {
    margin-bottom: 40px;

    .room {
      .details {
        width: 515px;

        .basic {
          .title {
            width: 230px;
            height: 25px;
            margin-bottom: 25px;
          }

          .guest {
            width: 480px;
            height: 25px;
            margin-bottom: 15px;
          }
        }

        .amenity {
          width: 140px;
          height: 30px;
        }
      }

      .controls-price {
        align-content: flex-end !important;
      }
      .price {
        width: 140px;
        height: 50px;
      }

      .select {
        width: 160px;
        height: 50px;
      }
    }
  }

}
