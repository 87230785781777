// colors
$color-white: #fff;

$color-grey-1: #f8f8f8;
$color-grey-2: #c1cdd9;
$color-grey-3: #9dadbc;
$color-grey-4: #8797a6;
$color-grey-5: #394955;
$color-grey-skeleton: #eee;

$color-red-1: #fff3f5;
$color-red-2: #fcc7d0;
$color-red-3: #dc3250;
$color-red-4: #b7495c;

$color-green-1: #03b871;
$color-green-2: #329b72;

$color-blue-1: #8fcae2;
$color-blue-2: #1986e8;
$color-blue-3: #0a6ac0;
$color-blue-4: #1e8cc3;
$color-blue-5: #18719d;

// text
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 20px;

// effects
$box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

// sizes
$mobile-width: 600px;
