@import '~normalize.css/normalize.css';
@import 'styles/_constants.scss';

html {
  scroll-behavior: smooth;
  transition-duration: 300ms;
}

body {
  background-color: $color-grey-1;
}

label {
  margin-right: 10px;
}

input {
  height: 45px;
  width: 130px;
  font-size: 14px;
  padding: 0 15px;
  border: 2px solid $color-grey-4;
  color: $color-grey-5;

  &:focus {
    outline: none;
  }

  &.date-picker-input {
    text-align: left;
    cursor: pointer;
  }

  &.warning {
    color: $color-red-3 !important;
    border: 2px solid $color-red-3 !important;
    background-color: $color-red-1;
  }
}

.select {
  position: relative;

  select {
    height: 45px;
    width: 130px;
    font-size: 14px;
    padding: 0 15px;
    cursor: pointer;
    appearance: none;

    &:focus {
      outline: none;
    }

    &.warning {
      color: $color-red-3 !important;
      border: 2px solid $color-red-3 !important;
      background-color: $color-red-1;
    }
  }

  svg {
    position: absolute;
    pointer-events: none;
  }
}

button {
  height: 70px;
  width: 290px;
  font-size: $font-size-xl;
  cursor: pointer;
  color: $color-white;
  background-color: $color-blue-2;
  border: none;

  &:hover {
    background-color: $color-blue-3;
  }

  &:focus {
    outline: none;
  }
}

.date-picker {
  position: relative;
}

h1, h2, h3, h4 {
  margin: 0;
}

.select-language {
  select {
    color: $color-grey-5;
    font-size: $font-size-xs;
    height: 25px;
    width: 105px;
    border: 1px solid $color-grey-5;
    border-top: none;
    background-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: 0;
    padding-left: 30px;

    &:focus, &:hover {
      outline: none;
    }
  }

  svg {
    right: 8px;
    top: 10px;

    &:first-child {
      right: 85px;
      top: 6px;
    }
  }
}

.App {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-m;
  color: $color-grey-5;
  width: 1050px;
  margin: 0 auto;

  .header {
    .property-name {
      font-weight: bold;
      font-size: 25px;
      padding: 20px;
      border-left: $color-grey-5 solid 10px;
    }

    .header-strip {
      height: 280px;

      .property-description {
        float: left;
        vertical-align: top;
        width: 348px;
        padding-right: 30px;
        margin-top: 30px;

        a {
          font-size: $font-size-s;
          margin-bottom: 25px;
          color: $color-blue-3;

          &:hover {
            color: $color-blue-2;
          }

          .location-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;

            svg {
              height: 30px;
              width: 20px;
            }
          }

          .location-details {
            display: inline-block;
            vertical-align: middle;
            line-height: 15px;

            .country {
              text-transform: uppercase;
            }
          }
        }

        .description {
          margin-top: 20px;
        }
      }

      .image-container {
        width: 672px;
        float: right;
        vertical-align: top;
        height: 280px;
        cursor: pointer;

        .main-image {
          height: 100%;
          width: 420px;
          margin-right: 2px;
          background-repeat: no-repeat;
          object-fit: cover;
          display: inline-block;
          vertical-align: top;
        }

        .side-images {
          display: inline-block;
          position: relative;

          .side-image-1, .side-image-2 {
            display: block;
            height: 139px;
            width: 250px;
            background-repeat: no-repeat;
            object-fit: cover;
          }

          .side-image-1 {
            margin-bottom: 2px;
          }

          .open-gallery {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: $font-size-xs;
            text-transform: uppercase;
            padding: 12px;
            background: rgba(255,255,255,0.8);
          }
        }
      }
    }
  }

  .stay {
    height: 120px;
    background-color: $color-white;
    padding: 0 40px;

    .container {
      line-height: 120px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: center;
      align-items: center;

      .input {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;

        &.date-picker {

          label {
            font-weight: bold;
          }

          .rc-calendar-picker {
            z-index: 1005;
          }

          svg {
            position: absolute;
            right: 10px;
            top: calc(50% - 3px);
            width: 12px;
            pointer-events: none;
            height: 9px;

            &:first-of-type {
              right: 210px;
              top: calc(50% - 13px);
              width: 27px;
              height: 25px;
            }
          }

          .date-picker-input {
            text-align: left;
            width: 250px;
            border: 1px solid $color-grey-2;
            height: 40px;
            padding-left: 60px;
            box-sizing: border-box;
          }
        }

        .nights-count {
          text-align: right;
          order: 0;
          flex: 0 1 auto;
          align-self: auto;
        }
      }
    }
  }

  .rooms {
    margin-top: 10px;
  }
}

footer {
  background-color: $color-grey-5;
  color: $color-white;
  height: 120px;
  width: 100%;
  margin-top: 60px;
  position: static;
  bottom: 0;
  z-index: 1000;

  &.sticky {
    position: sticky;
  }

  .container {
    width: 1010px;
    height: 60px;
    padding: 30px 20px;
    margin: 0 auto;

    .info {
      display: inline-block;
      vertical-align: top;
      width: 275px;
      margin-right: 15px;
      line-height: 30px;

      span {
        line-height: 26px;
        vertical-align: top;
        margin-left: 10px;
      }

      svg {
        height: 26px;
        width: 26px;
      }
    }

    .price {
      display: inline-block;
      vertical-align: top;
      width: 210px;
      margin-right: 15px;
      height: 100%;
      font-size: $font-size-s;
      line-height: 20px;

      .price-container {
        padding-left: 20px;
        border-left: 1px solid $color-white;
        height: 100%;

        img {
          height: 20px;
        }

        .amount-label {
          display: inline-block;
          margin-right: 4px;
        }

        .amount {
          font-size: 20px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 10px;

          &.amount-small {
            font-size: 16px;
          }

          &.with-extra-charges {
            display: inline-block;
            margin-top: 5px;

            &:first-of-type {
              margin-top: 0px;
            }
          }
        }
      }
    }

    .controls {
      display: inline-block;
      vertical-align: top;
      width: 220px;
      margin-right: 15px;
      padding-left: 20px;
      border-left: 1px solid $color-white;
      height: 100%;

      .dates, .nights {
        font-size: $font-size-s;
        line-height: 20px;
        display: inline-block;
        margin-right: 5px;
      }

      .change-dates {
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .book {
      display: inline-block;
      vertical-align: top;

      button {
        text-transform: uppercase;
        height: 60px;
        width: 235px;

        svg {
          height: 15px;
          margin-left: 15px;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .App {
    width: 600px;

    .select-language {
      select {
        position: absolute;
        right: 10px;
      }

      svg {
        right: 18px;
        top: 10px;

        &:first-child {
          right: 95px;
          top: 6px;
        }
      }
    }

    .header {
      .property-name {
        background-color: $color-white;
        font-size: $font-size-xl;
        padding: 15px 110px 15px 20px;
        border: none;
      }

      .header-strip {
        height: auto;
        margin-bottom: 30px;

        .image-container {
          width: 600px;
          float: none;

          .main-image {
            width: 370px;
          }

          .side-images {
            .side-image-1, .side-image-2 {
              width: 228px;
            }
          }
        }

        .property-description {
          float: none;
          margin: 0;
          padding: 30px 20px 20px;
          width: auto;
        }
      }
    }

    .stay {
      margin: 0 20px;
      height: auto;
      padding: 20px 15px;

      .container {
        line-height: unset;
        justify-content: space-between;

        label {
          display: block;
          margin-bottom: 10px;
        }

        .input:not(.date-picker) {
          display: none;
        }

        .input.date-picker {
          .date-picker-input {
            width: 185px;
            height: 55px;
          }

          svg {
            position: absolute;
            right: 10px;
            bottom: 20px;
            top: unset;
            width: 12px;
            pointer-events: none;
            height: 9px;

            &:first-of-type {
              right: 210px;
              bottom: 13px;
              top: unset;
              width: 27px;
              height: 25px;
            }
          }

          .date-picker-input {
            text-align: left;
            width: 250px;
            border: 1px solid $color-grey-2;
            height: 50px;
            padding-left: 60px;
            box-sizing: border-box;
          }
        }

        button {
          margin-top: 30px;
          width: 100%;
          height: auto;
          padding: 10px 0;

          svg {
            display: none;
          }
        }

        .nights-count {
          display: block;
          width: 100%;
          text-align: left;
          margin-top: 20px;
        }
      }
    }

    .rooms {
      margin: 20px;
    }

  }

  footer {
    height: auto;

    .container {
      width: 560px;
      height: auto;
      padding: 20px;

      .info {
        display: block;
        margin-bottom: 10px;
        width: 100%;

        div:last-child {
          padding-bottom: 10px;
          border-bottom: 1px solid $color-white;
        }
      }

      .price {
        //width: 235px;
        margin-right: 0;
        float: right;

        .price-container {
          padding: 0;
          border: none;
          text-align: right;

          .amount {
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }

      .controls {
        display: inline-block;
        border: none;
        padding: 0;
        width: 295px;

        .nights {
          display: block;
        }

        .change-dates {
          display: none;
        }
      }

      .book {
        display: block;
        margin-top: 20px;
        width: 100%;

        button {
          width: 100%;
          height: 40px;
          font-size: $font-size-l;

          svg {
            display: none;
          }
        }
      }

    }
  }
}

@media (max-width: 768px) {
  .App {
    width: 100%;

    .select-language {
      select {
        position: absolute;
        right: 10px;
      }

      svg {
        right: 18px;
      }
    }

    .header {
      .header-strip {
        .image-container {
          width: 100%;

          .main-image {
            width: 100%;
            margin-right: 0;
          }

          .side-images {
            display: none;
          }
        }
      }
    }

    .stay {
      .container {
        display: block;
        .input.date-picker {
          margin-bottom: 15px;

          svg:first-of-type {
            left: 10px;
          }

          .date-picker-input {
            width: 100%;
            box-sizing: border-box;
          }

        }
      }
    }
  }

  footer {
    .container {
      width: 100%;
      box-sizing: border-box;

      .controls {
        display: block;
      }

    }
  }
}

.error {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .container {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 150px);
  }

  svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .error-text {
    margin: 0 auto;
    width: 300px;
    text-align: center;
  }
}
